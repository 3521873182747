@import 'app/common/styles/background-colors.module.scss';

.infoWrapper {
  background-color: $bg-island-inner;
  gap: 0.5rem;
  padding-block: 0.25rem;
  padding-inline: 0.75rem;
  border-radius: 0.5rem;
}

.header {
  position: absolute;
  inset-block-start: 1rem;
  inset-inline-end: 0;

  .link {
    display: flex;
  }

  .divider {
    height: 1rem;
  }
}
