@import 'app/common/styles/text-colors.module.scss';

.footer {
  padding: 0.5rem 0;
  box-shadow: 0 -0.5rem 2rem #fff;
  position: absolute;
  inset-inline-start: 0;
  inset-block-end: 0;
  width: 100%;

  .action {
    padding: 0.5rem 1rem;
  }
}
