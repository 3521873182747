@import 'app/common/layouts/main-layout/variables';

.toggleMenuButton {
  height: 100%;
  padding-block: 0.75rem;
  border-radius: 1.25rem;
  cursor: pointer;

  &Wrapper {
    position: sticky;
    inset-block-start: 0;
    width: 4rem;
    height: 100vh;
    padding: 0.5rem 0;

    &.hidden {
      display: none;
    }
  }
}

@media screen and (min-width: $mediaMaxWidth) {
  .toggleMenuButton {
    &Wrapper {
      display: none;
    }
  }
}
