@import 'app/common/styles/background-colors.module.scss';

.divider {
  border: 0px;
  border-style: solid;
}

.horizontal {
  border-top-width: 0.0625rem;
  height: 0;
}

.vertical {
  border-right-width: 0.0625rem;
  height: 100%;
  width: 0;
}

.main {
  border-color: $bg-border;
  color: white;
}

.white {
  border-color: $bg-globe;
}

.primary {
  border-color: $bg-accent;
}
