@import 'app/common/styles/background-colors.module.scss';
@import 'app/common/styles/text-colors.module.scss';
@import 'app/common/layouts/main-layout/variables';

.menu {
  position: relative;
  width: 100%;
  height: 100%;
  padding-block-start: 0.5rem;
  padding-block-end: 5.5rem;
  overflow: hidden;
  border-radius: 1.25rem;

  &Wrapper {
    position: sticky;
    inset-block-start: 0;
    width: 13rem;
    height: 100vh;
    min-height: 25rem;
    padding: 0.5rem 0;
    z-index: 100;
  }

  .navigationContainer {
    margin-block-start: 1.75rem;
    max-height: calc(100% - 6rem);
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .navigationContainerHeightWithThanksCurrency {
    max-height: calc(100% - 6.75rem);
  }
}

@media screen and (max-width: $mediaMaxWidth) {
  [dir="ltr"] .menu {
    &Wrapper {
      transform: translateX(-15rem);
    }
  }

  [dir="rtl"] .menu {
    &Wrapper {
      transform: translateX(15rem);
    }
  }

  .menu {
    &Wrapper {
      position: fixed;
      opacity: 0;
      transition: opacity 0.3s linear, transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);

      &.active {
        opacity: 1;
        transform: translateX(0);
      }

      &.pinned {
        position: sticky;
        inset-inline-start: 0;
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
}
