@import 'app/common/styles/background-colors.module.scss';

.header {
  position: fixed;
  inset-inline-start: 0;
  inset-block-start: 0;
  width: 100vw;
  min-width: 22.5rem;
  height: 3.5rem;
  padding-block: 0.75rem;
  padding-inline: 1rem 1.25rem;
  background-color: $bg-island;
  box-shadow: $bg-island-shadow;
  z-index: 100;

  .link {
    display: flex;
  }

  .divider {
    height: 1rem;
  }
}

.profileIcon {
  margin-inline-end: 0.5rem;
}

.avatar {
  cursor: pointer;
}
