@import 'app/common/styles/background-colors.module.scss';
@import 'app/common/styles/breakpoints.module.scss';

.overlay {
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-width: 22.5rem;
  height: 100%;
  padding-block-end: 1rem;
  background-color: $bg-island;
  overflow: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  z-index: 1000;

  &::-webkit-scrollbar {
    display: none;
  }

  animation: hide 0.3s ease-out forwards;
  will-change: animation;

  &.active {
    animation: show 0.3s ease-in forwards;
  }

  &.clickable {
    cursor: pointer;
  }

  @include xl-breakpoint {
    padding-block-start: 2rem;
    padding-block-end: 5rem;
    background-color: $bg-modal;
    overflow: scroll;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hide {
  from {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
